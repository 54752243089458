var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-around"},[_c('v-slide-group',{attrs:{"show-arrows":!_vm.isMobile,"center-active":"","mandatory":""},nativeOn:{"touchstart":function($event){$event.stopPropagation();},"touchmove":function($event){$event.stopPropagation();},"touchend":function($event){$event.stopPropagation();}},model:{value:(_vm.userIndex),callback:function ($$v) {_vm.userIndex=$$v},expression:"userIndex"}},_vm._l((_vm.users),function(user,index){return _c('v-slide-item',{key:index,staticClass:"mx-1",nativeOn:{"touchstart":function($event){$event.stopPropagation();},"touchmove":function($event){$event.stopPropagation();},"touchend":function($event){$event.stopPropagation();}}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"card mt-6"},[_c('v-row',{attrs:{"justify":"space-around","align":"center"}},[(_vm.isMobile)?_c('v-btn',{attrs:{"fab":"","color":"primary","disabled":_vm.disableUserNav},on:{"click":() => {
                    if (_vm.userIndex > 0) {
                      _vm.userIndex--
                    }
                    else {
                      _vm.userIndex = _vm.users.length - 1
                    }
                    _vm.blockNavButtons()
                  }}},[_vm._v(" < ")]):_vm._e(),_c('v-avatar',{attrs:{"size":"150","rounded":"true","color":"primary"}},[(user.photo?.file?.uri)?_c('v-img',{attrs:{"src":user.photo?.file?.uri,"width":171,"height":171}}):_c('h1',{staticClass:"text-h2",style:({ color: 'white' })},[_vm._v(" "+_vm._s(user.person.firstName[0])+" "+_vm._s(user.person.surname[0])+" ")])],1),(_vm.isMobile)?_c('v-btn',{attrs:{"fab":"","color":"primary","disabled":_vm.disableUserNav},on:{"click":() => {
                    if (_vm.userIndex < _vm.users.length) {
                      _vm.userIndex++
                    }
                    else {
                      _vm.userIndex = 0
                    }
                    _vm.blockNavButtons()
                  }}},[_vm._v(" > ")]):_vm._e()],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold executive-name px-2 py-1 d-flex justify-center",attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s(user.person.name)+" "),(user.userIsOnline)?_c('p',{staticClass:"font-weight-light align-end"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]),_vm._v(" En línea ")],1):_vm._e()])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('period-records',{attrs:{"records":_vm.chartData?.currentMonth?.inspections,"process-settings":_vm.chartData.settings.inspection,"target-field-name":"accepted","default-target":10,"title":"Inspecciones","filtering-method":record => record.inspectorId === user.person.employee.id && record.inspectorQualification !== null,"use-total-as-target":""}})],1)],1),_c('h2',{staticClass:"text-h6 text-center mt-4"},[_vm._v(" Acumulado de inspecciones último mes ")]),_c('v-row',[_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
                    if (!isIntersecting) return
                    _vm.submitQueries([
                      {category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections},
                      {category: 'prevMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.prevMonthFilters.inspections},
                    ])
                  }),expression:"(entries, observer, isIntersecting) => {\n                    if (!isIntersecting) return\n                    submitQueries([\n                      {category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections},\n                      {category: 'prevMonth', field: 'inspections', queryMethod: getInspections, queryFilter: prevMonthFilters.inspections},\n                    ])\n                  }"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"prev-month-records":_vm.chartData.prevMonth.inspections,"criterias":[
                    {
                      name: 'Inspecciones',
                      color: '#6185DB',
                      filter: (record) => Boolean(record.inspectorQualification),
                    },
                  ],"path-to-date":['createdAt'],"data-transformer":records => _vm.normaliceData(records).filter(record => record.inspector.id === user.person.employee.id && record.inspectorQualification !== null),"type":"line","accumulated":true,"dates":_vm.dates,"show-total":false,"sort-legends":false}})],1),_c('h2',{staticClass:"text-h6 text-center mt-4"},[_vm._v(" Inspecciones finalizadas ")]),_c('v-row',[_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
                    if (!isIntersecting) return
                    _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n                    if (!isIntersecting) return\n                    submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"filtering-criteria":record => record.inspector.id === user.person.employee.id && record.inspectorQualification !== null,"total-filter":record => record.inspectorQualification !== null,"path-to-details":[
                    {
                      name: 'Fecha de inicio',
                      pathToComplement: ['inspector', 'date'],
                    },
                    {
                      name: ['executive', 'name'],
                      pathToComplement: ['executive', 'scheduledDate'],
                    },
                  ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"path-to-color":['status', 'color'],"sorting-method":_vm.sortByScheduled,"default-target":_vm.inspectionsLength,"show-total-comparison":"","show-extra-total-line":"","comparison-sufix":"agendadas","total-name":"agendadas"}})],1)],1)]},proxy:true}],null,true)})],1)}),1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Acumulado de inspecciones"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections},
                {category: 'prevMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.prevMonthFilters.inspections},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections},\n                {category: 'prevMonth', field: 'inspections', queryMethod: getInspections, queryFilter: prevMonthFilters.inspections},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"prev-month-records":_vm.chartData.prevMonth.inspections,"criterias":[
              {
                name: 'Agendada',
                color: '#FAD901',
                filter: (record) => true,
              },
              {
                name: 'Cancelada',
                color: '#F03738',
                filter: (record) => record.closingReason?.description === 'Cancelada',
              },
              {
                name: 'Finalizada',
                color: '#13CE66',
                filter: (record) => Boolean(record.inspectorQualification),
              },
              {
                name: 'Validada',
                color: '#7D67BC',
                filter: (record) => Boolean(record.supervisorQualification),
              },
              {
                name: 'Compradas',
                color: '#6185DB',
                filter: (record) => Boolean(record.bought),
              },
            ],"path-to-date":['createdAt'],"data-transformer":_vm.normaliceData,"type":"line","accumulated":true,"dates":_vm.dates,"show-total":false,"sort-legends":false}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Inspecciones último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.lastYearFilters.inspections},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'inspections', queryMethod: getInspections, queryFilter: lastYearFilters.inspections},\n              ])\n            }"}],attrs:{"records":_vm.chartData.lastYear.inspections,"prev-month-records":[],"criterias":[
              {
                name: 'Agendada',
                color: '#FAD901',
                filter: (record) => true,
              },
              {
                name: 'Cancelada',
                color: '#F03738',
                filter: (record) => record.closingReason?.description === 'Cancelada',
              },
              {
                name: 'Finalizada',
                color: '#13CE66',
                filter: (record) => Boolean(record.inspectorQualification),
              },
              {
                name: 'Validada',
                color: '#7D67BC',
                filter: (record) => Boolean(record.supervisorQualification),
              },
              {
                name: 'Compradas',
                color: '#6185DB',
                filter: (record) => Boolean(record.bought),
              },
            ],"path-to-date":['createdAt'],"data-transformer":_vm.normaliceData,"type":"line","dates":_vm.lastYearDates,"monthly":"","show-total":false,"sort-legends":false}})]},proxy:true}])})],1)],1),_c('v-row',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.getInspectors),expression:"getInspectors"}]},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Finalizadas por inspector"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","path-to-category":['inspector'],"path-to-category-title":['name'],"filtering-criteria":(record) => Boolean(record.inspectorQualification),"data-transformer":records => _vm.normaliceData(records).filter(record => record.inspector.id),"use-total-as-target":"","category-name":"inspector","force-progress-bar":"","total-name":"iniciadas"}})]},proxy:true}])})],1),_vm._l((_vm.inspectors.filter(inspector => inspector.name !== 'Sin inspector')),function(inspector,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":inspector.name},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"filtering-criteria":record => record.inspector.id === inspector.id,"path-to-details":[
              {
                name: 'Fecha de inicio',
                pathToComplement: ['inspector', 'date'],
              },
              {
                name: ['executive', 'name'],
                pathToComplement: ['executive', 'scheduledDate'],
              },
            ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"path-to-color":['status', 'color'],"sorting-method":_vm.sortByScheduled,"hard-target":_vm.finishedInspectionsLength,"path-to-subtitle-icon":['subtitleIcon'],"show-extra-total-line":"","use-total-as-target":"","show-total-comparison":"","total-filter":record => record?.inspectorId,"comparison-sufix":"iniciadas","total-name":"iniciadas"}})]},proxy:true}],null,true)})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }