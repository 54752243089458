
  import { deepCopy, localToUtc } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Watch } from 'vue-property-decorator'
  import SupervisorDashboard from '../SupervisorDashboard'
  import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class SupervisorInspectors extends SupervisorDashboard {
  inspectors: Record<string, any> = []
  inspectionsLength: Number = 0
  userIndex = 0
  disableUserNav = false
  users: any = []
  finishedInspectionsLength: Number = 0
    // Methods
    beforeCreate () {
      this.getData = async () => {
        await this.getInspectors()
        this.$set(this.chartData.currentMonth, 'inspections', await this.getInspections(this.currentMonthFilters.startEndDate))
        this.$set(this.chartData.prevMonth, 'inspections', await this.getInspections(this.prevMonthFilters.startEndDate))
      }
    }

    removeOtherStates (records) {
      return this.normaliceData(records)
        .filter(record => record.closingReason?.type?.name !== 'expired')
        .filter(record => !record.inspectorQualification)
    }

    sortByScheduled (records) {
      return records.sort((prev, next) => {
        const prevDate = dayjs(prev.scheduledDate)
        const nextDate = dayjs(next.scheduledDate)

        if (prevDate.isBefore(nextDate)) {
          return 1
        } else if (prevDate.isAfter(nextDate)) {
          return -1
        }
        return 0
      })
    }

    normaliceData (records) {
      return records.map(record => {
        const {
          executive,
          executiveId,
          autoDescription,
          year,
          inspector,
          inspectorId,
          closingReason,
          status,
          scheduledDate,
          closingReasonDate,
        } = record
        const attributes = [
          'executive',
          'executiveId',
          'autoDescription',
          'year',
          'inspector',
          'inspectorId',
          'status',
          'scheduledDate',
          'closingReasonDate',
        ]
        const corrected = deepCopy(record)
        attributes.forEach(attribute => delete corrected[attribute])
        return {
          ...corrected,
          car: `${autoDescription} ${year}`,
          executive: {
            id: executiveId,
            name: executive?.toUpperCase() || 'SIN EJECUTIVO',
            scheduledDate: dayjs(localToUtc(scheduledDate)).format('DD/MM/YY - HH:mm'),
          },
          inspector: {
            id: inspectorId,
            name: inspector?.toUpperCase() || 'SIN INSPECTOR',
            closingReasonDate: dayjs(localToUtc(closingReasonDate)).format('DD/MM/YY - HH:mm'),
          },
          status: closingReason || status,
          subtitleIcon: record.inspectorQualification
            ? {
              icon: 'mdi-car-search',
              text: 'Finalizada',
              color: '#6185DB',
            } : undefined,
        }
      })
    }

    getCategories (records: Record<string, any>, path: string[], categoryName = 'categoría', debugging = false): Record<string, any> {
      const categories = []
      if (!records) return categories
      records.forEach(record => {
        let category = this.getObjectAttribute(record, path)
        if (!category?.id) {
          category = {
            id: undefined,
            description: `Sin ${categoryName}`,
            name: `Sin ${categoryName}`,
            color: '#737373',
          }
        }
        const containsCategory = categories.map(category => category?.id).includes(category?.id)
        if (!containsCategory) {
          categories.push(category)
        }
      })
      return categories
    }

    getObjectAttribute (object, path: string[]): any {
      if (!object || !path) return undefined
      const pathCopy = deepCopy(path)
      if (pathCopy.length > 0) {
        const attribute = pathCopy.shift()
        const returnValue = object?.[attribute]
        if (returnValue !== undefined) {
          return this.getObjectAttribute(returnValue, pathCopy)
        } else {
          return undefined
        }
      }
      return object
    }

    async getInspectors () {
      const inspections = await this.getInspections(this.currentMonthFilters.inspections)
      if (!inspections) return []

      const normaliced = this.normaliceData(inspections?.records)

      const inspectors = this.getCategories(normaliced, ['inspector'], 'inspector')
      this.inspectors = inspectors
      this.inspectionsLength = normaliced.length
      this.finishedInspectionsLength = normaliced.filter(record => record.inspector.id).length
    }

    async getUser (idPerson) {
      const user = (await this.fetchData({
        query: {
          name: 'find',
          model: 'User',
        },
        filter: {
          id_person: { _eq: idPerson },
        },
      }))[0]
      const personProcess = (await this.fetchData({
        query: {
          name: 'find',
          model: 'Process',
        },
        filter: {
          table_name: { _eq: 'person' },
        },
      }))[0]
      const photo = (await this.fetchData({
        query: {
          name: 'find',
          model: 'FileProcess',
        },
        filter: {
          _and: [
            { id_process_record: { _eq: idPerson } },
            { parameter: { process: { id: { _eq: personProcess.id } } } },
          ],
        },
      }))[0]
      return {
        ...user,
        photo,
      }
    }

  // Getters

  // Watchers
    @Watch('inspectors')
    async updateExecutives () {
      const { inspectors, getUser } = this
      this.users = (await Promise.all(inspectors.filter(inspector => inspector.id).map(async inspector => {
        const inspectorData = (await this.fetchData({
          query: { name: 'findLite', model: 'Employee' },
          filter: { id: { _eq: inspector.id } },
        }))[0]
        if (inspectorData.person) {
          return getUser(inspectorData.person.id)
        }
      }))).filter(user => user)
    }
  }
